import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { BlurredSpinner, UserAva } from 'components';
import ParsedTextWithLink from 'components/ParsedTextWithLink';
import { useAppSelector } from 'hooks';
import { DateTime } from 'luxon';
import MoveToResponder from 'modules/Conversation/components/MoveToResponder';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getSelectedTimeZone } from 'store/slices/authSlice';
import { theme } from 'theme';
import { Datasource, ISourceUser, UserAvaType } from 'types';
import { convertNumbersToK, getDateSourceIcon } from 'utils';

import { emptyImageConfig } from '../../../utils';
import styles from './styles.module.scss';

type PostItemProps = {
  data: {
    _id: string;
    stock: string;
    text: string;
    date: string;
    user: ISourceUser;
    dataSource: number;
    link: string;
    isResponderMark?: boolean;
  };
  loading?: boolean;
};

const PostItem: React.FC<PostItemProps> = ({ data, loading = false }) => {
  const { _id, stock, text, user, date, dataSource, link, isResponderMark } = data;
  const { image, displayName, followers } = user;
  const { t } = useTranslation();
  const selectedTimeZone = useAppSelector(getSelectedTimeZone);
  const luxonDateTime = DateTime.fromJSDate(new Date(date));
  const formatedDate = luxonDateTime.setZone(selectedTimeZone).toFormat('d LLL, yyyy HH:mm');
  const Icon = getDateSourceIcon(dataSource);

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <p className={styles.text}>
          <ParsedTextWithLink text={text} />
        </p>
        <div className={styles.source}>
          <Icon />
        </div>
      </div>

      <div className={styles.row}>
        <UserAva
          type={UserAvaType.topPost}
          data={{
            name: displayName,
            src: image || emptyImageConfig[dataSource] || '',
            bottomLabel: `${convertNumbersToK(followers)} ${t('posts.followers')}`
          }}
        />
        <div className={styles.labels}>
          <div className={styles.flex}>
            <AccessTimeIcon sx={{ fontSize: 20, margin: 0, color: theme.palette.grey[400] }} />
            <span className={styles.label}>{formatedDate}</span>
            <a className={styles.outerLink} href={link} target={'blank'} rel={'noopener'}>
              {t(`buttons.${dataSource === Datasource.YouTube ? 'viewVideo' : 'viewPost'}`)}
            </a>
          </div>
        </div>
      </div>
      <div className={styles.moveResponderWrapper}>
        <MoveToResponder convId={_id} stock={stock} defaultInResponder={!!isResponderMark} />
      </div>
      {loading && <BlurredSpinner size={32} fillContent={true} />}
    </div>
  );
};

export default PostItem;
